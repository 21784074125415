<template>
  <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
    <div class="receipt">

      <SubHeader title="고객 정보" :alert="false"></SubHeader>

      <div class="bg-body p-3 shadow-200 rounded-3 pb-4" ref="receipt">
        <div>
          <div class="border border-gray-relative-700"></div>
          <h5 class="py-2">상세 정보</h5>
          <div class="cutting_line border-gray-relative-500 pb-4"></div>
        </div>
        <ul class="small">
          <li class="d-flex justify-content-between mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">이름</p>
            <p class="w-100 text-end">홍길동</p>
          </li>
          <li class="d-flex justify-content-between mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">회원 아이디</p>
            <p class="w-100 text-end">
              test@gmail.com
            </p>
          </li>
          <li class="d-flex justify-content-between align-items-center mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">휴대폰 번호</p>
            <p class="w-100 text-end d-flex justify-content-end align-items-center">
              010-0000-0000
              <span
                class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-2 d-flex flex-column align-items-center justify-content-center shadow-100 border"
                @click="pp=true;"
              >
                <i class="fal fa-envelope text-muted"></i>
              </span>
            </p>
          </li>
          <li class="d-flex justify-content-between mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">보유 잔여금액</p>
            <p class="w-100 text-end">0</p>
          </li>

          <li class="d-flex justify-content-between mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">보유 상품권</p>
            <p class="w-100 text-end">
              0
            </p>
          </li>
          <li class="d-flex justify-content-between mb-2">
            <p class="flex-shrink-0 text-gray-relative-500 me-3">보유 캐쉬</p>
            <p class="w-100 text-end">0</p>
          </li>
        </ul>
        <div class="">
          <div class="mt-5">
            <div class="border border-gray-relative-700"></div>
            <h5 class="py-2">고객 상담이력</h5>
            <div class="cutting_line border-gray-relative-500 pb-4"></div>
          </div>
          <ul class="small">
            <li class="d-flex flex-column justify-content-between align-items-start mb-3">
              <p class="flex-shrink-0 text-gray-relative-500 mb-1">상담경로</p>
              <div class="w-100">
                <select class="form-control thick bg-body border-gray-relative-300">
                  <option class="text-body" value="" selected disabled>상담경로 선택</option>
                  <option class="text-body" value="INBOUND">INBOUND</option>
                  <option class="text-body" value="OUTBOUND">OUTBOUND</option>
                </select>
              </div>
            </li>
            
            <li class="d-flex flex-column justify-content-between align-items-start mb-2">
              <p class="flex-shrink-0 text-gray-relative-500 mb-1">상담내용</p>
              <div class="w-100 border-top max-h-px-300 overflow-auto" style="max-height:300px;">
                <ul class="board_list last-border-0" v-if="counsel.length>0" >
                  <li v-for="(item,index) in counsel" :key="index" class="border-bottom border-gray-relative-200 ">
                      <div class="txt_box d-flex w-100 align-items-center py-3 px-2" @click="open_counsel_idx == item.idx ? open_counsel_idx=-1 : open_counsel_idx = item.no">
                          <div>
                              <p class="tit" :class="{'text-truncate-1' : open_counsel_idx != item.no}">
                                  <pre style="white-space: pre-wrap;">{{item.memo}}</pre>
                              </p>
                              <div class="lh-1 mt-2 text-gray-relative-500 ">
                                  <span>{{item.date}}</span>
                              </div>
                          </div>
                          <i class="fal ps-3 ms-auto text-gray-relative-500" :class="{'fa-chevron-down':open_counsel_idx != item.no, 'fa-chevron-up':open_counsel_idx == item.no}"></i>
                      </div>
                      <div class="txt_box px-3 py-4 bg-gray-relative-100 border-top border-gray-relative-200" v-if="open_counsel_idx == item.no">
                          <div v-if="item.answer != ''">
                              <pre style="white-space: pre-wrap;">{{item.answer}}</pre>
                          </div>
                          <div class="small" v-else>
                              아직 답변이 없습니다.
                          </div>
                      </div>
                  </li>
              </ul>
              
              <div class="empty_data" v-else>
                  <p class="text-center py-5">
                      상담 내역이 없어요.
                  </p>
              </div> 
              </div>
            </li>
            <li class="cutting_line border-gray-relative-500 pb-4"></li>
            <li class="d-flex flex-column justify-content-between align-items-start mb-2">
              
              <div class="w-100 flex-between-center mb-2">
                <p class="flex-shrink-0 text-gray-relative-500 mb-1">상담이력 작성</p>
                <button class="btn btn-main text-white small btn-sm">저장</button>
              </div>
              
              <textarea class="form-control post_desc border rounded p-3 w-100 small bg-gray-relative-0 border-gray-relative-300" rows="10" v-model="counsel_desc" maxlength="1000" placeholder="여기에 상담이력을 작성하세요.">
              </textarea>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-between-center mt-4">
        <button
          @click="$router.go(-1)"
          class="btn thick btn-main w-100 text-white"
        >
          확인
        </button>
      </div>
    </div>
    <div class="basic_pp bottom_up" v-show="pp"  :class="{'off':off}">
        <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
        <div class="content bg-body text-center" ref="basic_pp_body">
            
            <div class="flex-between-center mb-2 ">
              <b class="fs-px-14">문자 발송</b>
                <button class="btn border-0" @click="pp_off()"><i class="fal fa-times ms-auto text-gray-relative-500"></i></button>
            </div>
            <select class="form-control thick bg-body border-gray-relative-300 mb-1" :class="{'text-gray-500':message==null, 'text-body':message!=null}" v-model="message" >
              <option class="text-body" value="">메시지 문구 선택</option>
              <option class="text-body" v-for="(item,index) in selectList" :key="index" :value="item.value">{{item.text}}</option>
            </select>
            <div v-if="message == 4" class="mt-3">
              <div class="form-group mt-2">
                <textarea class="form-control post_desc border rounded p-3 w-100 small bg-gray-relative-0 border-gray-relative-300" rows="10" v-model="desc" maxlength="1000" placeholder="여기에 문구를 입력하세요.">
                </textarea>
              </div>
            </div>
            <button class="mt-3 btn btn-main w-100 text-white shadow-sm" @click="pp_off">발송</button>
        </div>
    </div>
  </div>
</template>

<script>

import SubHeader from '@/components/common/SubHeader.vue'
export default {
    components:{
        SubHeader
    },
  data() {
    return {         
      pp: false,
			off: false,
      timeout_id: null,

      message:'',
      counsel: [
        {
          idx:0,
          no:0,
          memo:'임시',
          date:'2024-10-06 (일)',
          answer:'답변'
        },
        {
          idx:1,
          no:1,
          memo:'임시',
          date:'2024-10-06 (일)',
          answer:'답변'
        },
        {
          idx:2,
          no:2,
          memo:'임시',
          date:'2024-10-06 (일)',
          answer:'답변'
        },
        {
          idx:3,
          no:3,
          memo:'임시',
          date:'2024-10-06 (일)',
          answer:'답변'
        },
      ],
      
      open_counsel_idx: -1,
      
      selectList:[
          {
            value:'1',
            text:'1. 회원앱 결제 요청(회원앱 결제 방법)'
          },
          {
            value:'2',
            text:'2. 회원앱 결제완료 문자'
          },
          {
            value:'3',
            text:'3. 골프장 결제완료 문자'
          },
          {
            value:'4',
            text:'4. 기타 문구 수기 작성'
          }
        ],
    };
  },
  methods: {
    pp_off(){
      this.off = true;

      if (this.timeout_id) {
          clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
          this.pp = false;
          this.off = false;
          if( this.$refs.basic_pp_body ){
              this.$refs.basic_pp_body.scrollTop = 0;}
          this.timeout_id = null;
      }, 200);
  }
  
  },
  mounted() {
  },
};
</script>
